<template>
  <v-container class="px-0 py-0">
    <v-layout v-if="noType">
      <v-flex
        xs12
        md12
        pl-0>
        <select-phones
          label="Telefone"
          :disabled="isDisabled"
          :items="setOptions"
          :rules="phoneRules"
          :data-cy="dataCy"
          :empty="isEmptyData"
          :multiple="multiple"
          @change="changePhone" />
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <v-flex
        xs8
        md7
        pl-0>
        <select-phones
          label="Telefone"
          :disabled="isDisabled"
          :items="setOptions"
          :rules="phoneRules"
          :data-cy="dataCy"
          :empty="isEmptyData"
          :multiple="multiple"
          @change="changePhone" />
      </v-flex>
      <v-flex
        xs4
        md5
        pr-0>
        <v-select
          v-model="phoneType"
          item-text="label"
          item-value="value"
          label="Tipo"
          :items="types"
          :disabled="isDisabled"
          :rules="requiredRules"
          @change="changeType" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import SelectPhones from '@/components/ui/SelectPhones'

  export default {
    components: { SelectPhones },
    mixins: [ validate ],
    props: {
      label: {
        type: String,
        default: ''
      },
      dataCy: {
        type: String,
        default: ''
      },
      index: {
        type: [String, Number]
      },
      options: {
        type: Array
      },
      noType: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isDisabled: false,
        isEmptyData: false,
        number: '',
        phone: [{
          countryCode: '55',
          areaCode: '',
          number: '',
          type: ''
        }]
      }
    },
    computed: {
      types () {
        return [
          {
            label: this.$t('user.phone.types.mobile'),
            value: 'mobile'
          },
          {
            label: this.$t('user.phone.types.residential'),
            value: 'residential'
          },
          {
            label: this.$t('user.phone.types.commercial'),
            value: 'commercial'
          },
          {
            label: this.$t('user.phone.types.others'),
            value: 'others'
          }
        ]
      },
      setOptions () {
        return this.options === undefined ? [] : this.options
      },
      phoneType () {
        const isArray = this.phone.constructor === Array
        return isArray ? this.phone[0].type : this.phone.type
      }
    },
    methods: {
      changePhone (phone) {
        this.phone = phone
        this.$emit('change', this.phone, parseInt(this.index))
      },
      changeType () {
        this.$emit('change', this.phone, parseInt(this.index))
      }
    }
  }
</script>
