<template>
  <v-combobox
    ref="select"
    v-model="selectedItem"
    v-mask="phoneMask"
    item-text="number"
    item-value="number"
    :items="items"
    :label="label"
    :menu-props="{contentClass: dataCy + ' small-chips'}"
    :data-cy="dataCy"
    :multiple="multiple"
    :disabled="disabled"
    :rules="rules"
    @input="inputItem">
    <template v-slot:selection="data">
      <v-chip
        v-if="data.item.number && data.item.number.length > 0"
        close
        small
        @click:close="clearField(data.item)">
        {{ data.item.number }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item" />
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.number }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action @click.stop>
          <v-btn
            text
            x-small>
            Remover
          </v-btn>
        </v-list-item-action>
      </template>
    </template>
  </v-combobox>
</template>

<script>
  import masks from '@/mixins/masks'

  export default {
    mixins: [ masks ],
    props: {
      label: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      dataCy: {
        type: String,
        default: ''
      },
      rules: {
        type: Array,
        default: () => ([])
      },
      disabled: {
        type: Boolean,
        default: false
      },
      empty: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedItem: [],
        phoneMask: '+55 ## ####-####'
      }
    },
    mounted () {
      this.$refs.select.$el.getElementsByTagName('input')[0].setAttribute('autocomplete', 'disabled')
    },
    methods: {
      inputItem (item) {
        if (item.constructor === Object) {
          return this.$emit('change', [this.selectedItem])
        }

        this._setPhone(item)
        this.$emit('change', this.selectedItem)
      },
      clearField (field) {
        if (this.multiple) {
          this.selectedItem = this.selectedItem.filter(item => {
            return item.number !== field.number
          })
        } else {
          this.selectedItem = [{type: ''}]
        }

        this.$emit('change', this.selectedItem)
      },
      setMask (value) {
        if (this.isMobile(value)) {
          this.phoneMask = this.mask.celPhone
        } else {
          this.phoneMask = this.mask.phone
        }
      },
      _setPhone(items) {
        const isArray = items.constructor === Array
        items = isArray ? items : [items]

        const phones = items.map(item => {
          if (item.constructor === Object) {
            this.setMask(item.number)
            return item
          }

          const type = this.isMobile(item) > 10 ? 'mobile' : 'residential'
          const regex = /(\+\d{2}) (\d{2})/
          const matches = item.match(regex)
          const countryCode = matches[1].replace(/\D+/g, '')
          const areaCode = matches[2]
          this.setMask(item)
          return { areaCode, number: item, type, countryCode }
        })
        this.selectedItem = isArray ? phones : phones[0]
      },
      isMobile(value) {
        const numbers = value.replace('+55', '').replace(/\D+/g, '').replace(/0/, '')
        const digit = parseInt(numbers[2] || 0)
        const digits = [6, 7, 8, 9]
        return digits.includes(digit)
      }
    }
  }
</script>
